import Swup from 'swup';
import anime from 'animejs';

const swup_options = {
    LINK_SELECTOR: 'a[href^="/"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup]), a[xlink\\:href]',
    elements: [
        '#swup',
        '.swup'
    ],
    animationSelector: '[class^="a-"]',
    cache: true,
    pageClassPrefix: '',
    scroll: true,
    animateScroll: false,
    debugMode: false,
    preload: true,
    support: true,
    disableIE: false,
    skipPopStateHandling: function(event) {
        if (event.state && event.state.source === 'swup') {
            return false;
        }

        return true;
    },
};

function resizeHeaderOnScroll() {
    const headerEl = document.querySelector('header');
    const distanceY = window.pageYOffset ||
        document.documentElement.scrollTop;
    const shrinkOn = 200;

    if (distanceY > shrinkOn) {
        headerEl.classList.add('scrolled');
    } else {
        headerEl.classList.remove('scrolled');
    }
}

function registerIOs() {
    const io_options = {
      rootMargin: '0px',
      threshold: 0.5
    };
    const element_in_view = (element) => {
        if (element[0].isIntersecting && !element[0].target.dataset.loaded) {
            element[0].target.dataset.loaded = true;
            const tl = anime.timeline();
            const stagger_els = element[0].target.querySelectorAll('.section-stagger');
            const fade_in_els = element[0].target.querySelectorAll('.section-fade');

            tl.add({
              targets: stagger_els,
              translateY: [30, 0],
              opacity: [0, 1],
              delay: anime.stagger(75),
              easing: 'easeOutExpo',
            })
            .add({
              targets: fade_in_els,
              opacity: [0, 1],
              duration: 1000,
              easing: 'linear',
            }, 0);
        }
    };
    const sections = document.querySelectorAll('section');

    sections.forEach((section) => {
        let observer = new IntersectionObserver(element_in_view, io_options);

        observer.observe(section);
    });
}

function setup_demo_page() {
  const calendly_script = document.createElement('script');
  const headerEl = document.querySelector('header');

  headerEl.classList.add('scrolled');
//   window.removeEventListener('scroll', resizeHeaderOnScroll);

  calendly_script.setAttribute(
    'src',
    'https://assets.calendly.com/assets/external/widget.js'
  );

  document.head.appendChild(calendly_script);
}

function setup_home_page() {
    // window.addEventListener('scroll', resizeHeaderOnScroll);
}

function process_unsupported_browser() {
  const els = document.querySelectorAll('.section-stagger');
  const images = document.querySelectorAll('.section-fade');

  for (let i = 0; i < els.length; i++) {
      els[i].style.opacity = 1;
  }

  for (let i = 0; i < images.length; i++) {
      images[i].style.opacity = 1;
  }
}


function process_page_load() {
  const page = window.location.pathname.split('/').pop();
//   const headerEl = document.getElementByClassName('header');

  switch (page) {
    case '':
      setup_home_page();
      break;
    case 'request-demo':
    case 'request-demo.html':
      setup_demo_page();
      break;
    case 'pricing':
    case 'pricing.html':
      setup_pricing_page();
      break;
    default:
    //   headerEl.classList.add('scrolled');
    //   window.removeEventListener('scroll', resizeHeaderOnScroll);
  }

  if (!('IntersectionObserver' in window) ||
    !('IntersectionObserverEntry' in window) ||
    !('intersectionRatio' in window.IntersectionObserverEntry.prototype)) {
      process_unsupported_browser();
    }
  else {
    registerIOs();
  }
}

function process_cookie_modal() {
    if (getCookie('cookie_accepted') !== 'true') {
        document.getElementById('cookies-overlay').style.display = 'block';
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const swup = new Swup(swup_options);
    process_cookie_modal();
    process_page_load();
}, false);

document.addEventListener('swup:contentReplaced', () => {
  process_page_load();
});

var accept_cookie_button = document.getElementById('accept_cookie_button');

accept_cookie_button.onclick = function acceptCookie () {
      setCookie('cookie_accepted', 'true');

      var hide = document.getElementById('cookies-overlay');

      if (hide) {
          hide.classList.remove('fade-in-up');
          hide.classList.add('fade-out-down');
      }
};

function getCookie(cname) {
    let name = cname + '=';
    let etCookie = document.cookie.split(';');
    for (let i = 0; i < etCookie.length; i++) {
        let et = etCookie[i];
        while (et.charAt(0) === ' ') {
            et = et.substring(1);
        }
        if (et.indexOf(name) === 0) {
            return et.substring(name.length, et.length);
        }
    }
    return '';
}

function setCookie(cname, cvalue, exdays) {
    let date = new Date();
    date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = 'expires' + date.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path/';
}

const currency_symbols = {
    GBP: '£',
    EUR: '€',
};

function change_currency(currency) {
    let sign_up_links = document.getElementsByClassName('sign_up_link');
    for (let i = 0; i < sign_up_links.length; i++) {
        const subscription_plan = sign_up_links[i].dataset.subscriptionPlan;
        sign_up_links[i].href =
            `https://tms.employtec.com/sign_up?currency=${currency}` +
            `&subscription_plan=${subscription_plan}`;
    }

    let currency_symbol = currency_symbols[currency];
    let currency_symbol_elements =
        document.getElementsByClassName('currency_symbols');
    for (let i = 0; i < currency_symbol_elements.length; i++) {
        currency_symbol_elements[i].innerHTML = currency_symbol;
    }

}

function setup_pricing_page() {
    var currency_radios = document.getElementsByClassName('currency-button');

    for (let i = 0; i < currency_radios.length; i++) {
        currency_radios[i].addEventListener('click', function toggleCurrencyChange() {
            change_currency(this.value);
        });
    }

    change_currency('EUR');
}
